<template>
  <CRow class="mb-5">
    <CCol lg="4" xl="3">
      <TAside class="card">
        <CListGroup>
          <CListGroupItem>
            <CRow>
              <CCol col="12" lg="12" xl="6">
                <TMessage content="From date" bold class="mb-2" />
                <TInputDateTime
                  :value.sync="dateRange.start"
                  timeline="start"
                  placeholder="Start date"
                  class="mb-3"
                  :key="startDateKey"
                />
              </CCol>
              <CCol col="12" lg="12" xl="6">
                <TMessage content="To date" bold class="mb-2" />
                <TInputDateTime
                  :value.sync="dateRange.end"
                  timeline="end"
                  placeholder="End date"
                  class="mb-3"
                  :key="endDateKey"
                />
              </CCol>
              <CCol col="12" class="mb-3">
                <SSelectStatus
                  :value.sync="status"
                  label="Status"
                  :options="[
                    {
                      id: '',
                      name: this.$t('All'),
                    },
                    {
                      id: 'not_payment_due_date_yet',
                      name: this.$t('Not payment due date yet'),
                    },
                    {
                      id: 'overdue_payment',
                      name: this.$t('Payment overdue'),
                    },
                    {
                      id: 'payment_due_today',
                      name: this.$t('Payment due date is today'),
                    },
                    {
                      id: 'payment_due_date_empty',
                      name: this.$t('No payment due date'),
                    },
                    {
                      id: 'outstanding',
                      name: this.$t('Payment not completed'),
                    },
                  ]"
                />
              </CCol>
              <CCol col="12" class="mb-3">
                <SSelectSupplier
                  label="Supplier"
                  :value.sync="filter.supplier_id"
                  noCustomLabel
                  prependAll
                />
              </CCol>
              <CCol col="12" class="mb-3">
                <SSelectCustomer
                  label="Buyer"
                  :value.sync="filter.buyer_id"
                  noCustomLabel
                  prependAll
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12" class="d-flex mt-2">
                <TButton
                  class="w-100"
                  content="Filter"
                  @click="onFilter"
                  :disabled="loading || !dateRange.end"
                />
                <TButtonClear
                  @click="clearFilter"
                  variant="outline"
                  class="ml-1"
                  size=""
                />
              </CCol>
            </CRow>
          </CListGroupItem>
          <CListGroupItem
            v-for="(supplier, index) in list"
            :key="`${index}`"
            class=""
            :color="
              selected.supplier_id_selected == supplier.supplier_id
                ? 'primary'
                : 'secondary'
            "
            :to="
              !loading
                ? _.getAppendRouteQuery($route, {
                    supplier_id_selected: supplier.supplier_id,
                  })
                : ''
            "
          >
            <div class="d-flex">
              <TMessage
                :content="
                  supplier.supplier
                    ? supplier.supplier.name
                    : supplier.supplier_id
                "
                color="primary"
                noTranslate
                :truncate="2"
              />
              <TMessageMoney
                :amount="supplier.outstanding"
                class="ml-auto"
                currency="JPY"
              />
            </div>
          </CListGroupItem>
          <CListGroupItem v-if="loading">
            <TSpinner :loading="loading" />
          </CListGroupItem>
          <CListGroupItem v-if="!list.length && !loading">
            <TMessageNotFound />
          </CListGroupItem>
          <SButtonLoadmore
            v-if="!loading && list.length"
            store="order.statistics_payment_due_date_suppliers"
            class="mt-2 mb-3"
          />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="8" xl="9" v-if="$route.query.supplier_id_selected">
      <div class="card p-3">
        <CRow>
          <CCol md="6">
            <TMessage content="Supplier" bold color="primary" />
            <TMessage
              v-if="!lodash.isEmpty(detail)"
              :content="
                detail.supplier ? detail.supplier.name : detail.supplier_id
              "
              noTranslate
              bold
              class="mb-2 h4"
            />
          </CCol>
          <CCol md="6">
            <TMessage content="Outstanding" bold color="primary" />
            <TMessageMoney
              :amount="detail.outstanding"
              class="h4"
              currency="JPY"
            />
          </CCol>
        </CRow>
      </div>
      <TTableAdvance
        :items="tableList"
        :fields="fields"
        store="order.purchase_payment"
        @click-clear-filter="clearFilter"
        noFilter
        v-if="fetchFirstTime"
        resource=""
      >
        <template #supplier="{ item }">
          <td>
            <TMessage
              :content="item.supplier.name"
              noTranslate
              class="text-nowrap"
            />
          </td>
        </template>
        <template #id="{ item }">
          <td>
            <TLink
              :content="item.id"
              :to="lodash.getReferenceLink('purchase', item.id)"
            />
          </td>
        </template>
        <template #outstanding="{ item }">
          <td>
            <TMessageMoney
              :amount="item.outstanding"
              :currency="item.currency_id"
            />
          </td>
        </template>
        <template #buyer_id="{ item }">
          <td>
            <TLink
              class="text-truncate-0"
              v-if="item.buyer"
              :content="item.buyer.name"
              :to="lodash.getReferenceLink('user', item.buyer_id)"
            />
          </td>
        </template>
        <template #items="{ item }">
          <td>
            <div v-for="it in item.items" :key="it.id" class="d-flex">
              <TMessage bold :content="it.product_id" noTranslate />
              <span v-if="it.payment_due_date" class="mx-1">-</span>
              <TMessageDateTime
                v-if="it.payment_due_date"
                :content="it.payment_due_date"
                dateOnly
              />
            </div>
          </td>
        </template>
        <template #balance="{ item }">
          <td>
            <div class="d-flex">
              <TMessageMoney
                :amount="item.paid"
                :currency="item.currency_id"
                color="muted"
                noBold
              />
              <span class="mx-1">/</span>
              <TMessageMoney
                :amount="item.balance"
                :currency="item.currency_id || 'JPY'"
                color="muted"
              />
            </div>
          </td>
        </template>
      </TTableAdvance>
    </CCol>
  </CRow>
</template>

<script>
import paymentDueDateMixin from "../../mixins/paymentDueDateMixin";
export default {
  mixins: [paymentDueDateMixin],
  data() {
    return {
      fields: [
        { key: "id", label: "Purchase Id", _style: "width: 200px" },
        { key: "items", label: "Product/ Payment due date" },
        {
          key: "buyer_id",
          label: "Buyer",
          _classes: "",
          _style: "width: 220px; min-width: 220px",
        },
        { key: "balance", label: "Paid/Cost of goods" },
        { key: "outstanding", label: "Outstanding", sorter: true },
      ],
      filterQueryPick: ["buyer_id", "supplier_id"],
      objectStore: "order.statistics_payment_due_date_suppliers",
      tableStore: "order.purchase_payment",
      mainKey: "supplier_id",
      keySelected: "supplier_id_selected",
      filterBetweenKey: "items.payment_due_date",
    };
  },
  watch: {
    "queryRoute.supplier_id_selected": {
      immediate: true,
      deep: true,
      handler(newId, oldId) {
        if (oldId != newId) {
          this.fetchTable();
          const objSelected = this.list.find((x) => x.supplier_id == newId);
          if (objSelected)
            this.$store.commit(
              "order.statistics_payment_due_date_suppliers.select",
              newId
            );
        }
      },
    },
  },
  methods: {
    appendRoute() {
      this.lodash.appendRouteQuery(this.$router, {
        query: this.lodash.pickBy({
          ...this.$route.query,
          buyer_id: this.filter?.buyer_id || "",
          supplier_id: this.filter?.supplier_id,
          start: this.dateRange?.start,
          end: this.dateRange?.end,
          status: this.status,
        }),
      });
    },
  },
};
</script>
